import { NavLink } from "react-router-dom";
import { ColoursSection } from "../ColoursSection/ColoursSection";
import { PageSection } from "../PageSection/PageSection";
import "./centuro.scss";
import { SecondPartOfFooter } from "../SecondPartOfFooter/SecondPartOfFooter";
import { MobileMenu } from "../MobileMenu/MobileMenu";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-scroll";
import { PageListLinks } from "../PageListLinks/PageListLinks";
import { PageFooterButton } from "../PageFooterButton/PageFooterButton";

export const CentrinoPage = () => {
  const colors = [
    {
      color: "#21AD72",
      codeName: "Green",
      name: "Green",
      subtitleNames: ["#21AD72", "R33, G173, B114"],
      order: "first",
      textColor: "black",
    },
    {
      color: "#26A2D8",
      codeName: "Blue",
      name: "Blue",
      subtitleNames: ["#26A2D8", "R38, G162, B216"],
      order: "second",
      textColor: "white",
    },
    {
      color: "#313137",
      codeName: "Grey",
      name: "Grey",
      subtitleNames: ["#313137", "R49, G49, B55"],
      order: "third",
      textColor: "white",
    },
    {
      color: "#D78442",
      codeName: "RawSinna",
      name: "Raw Sinna",
      subtitleNames: ["#D78442", "R215, G132, B66"],
      order: "fourth",
      textColor: "black",
    },
    {
      color: "#FFFFFF",
      codeName: "White",
      name: "White",
      subtitleNames: ["#FFFFFF", "R255, G255, B255"],
      order: "fifth",
      textColor: "black",
    },
    {
      color: "#F7357D",
      codeName: "Fuxia",
      name: "Fuxia",
      subtitleNames: ["#F7357D", "R247, G53, B125"],
      order: "sixth",
      textColor: "black",
    },
  ];

  return (
    <>
      <header className="page__header__main-container">
        <div className="page__header-container">
          <MobileMenu />
          <div className="page__header">
            <NavLink to="/" className="page-logo-container">
              <img
                src="images/S-letter.svg"
                className="page-logo page-logo-S"
                alt="logo"
              />
              <img
                src="images/O-letter.svg"
                className="page-logo page-logo-O"
                alt="logo"
              />
              <img
                src="images/B-letter.svg"
                className="page-logo page-logo-B"
                alt="logo"
              />
              <img
                src="images/E-letter.svg"
                className="page-logo page-logo-E"
                alt="logo"
              />
              <img
                src="images/X-letter.svg"
                className="page-logo page-logo-X"
                alt="logo"
              />
            </NavLink>
            <PageListLinks />
          </div>
          <div className="safe__main-container">
            <div className="lumina__title-container">
              <h1 className="greit__title centuro__title">Centrino</h1>
              <h2 className="shell__subtitle">
                cryptocurrency exchange interface
              </h2>
            </div>
            <img
              src="images/notebook-img-centrino-header.png"
              className="centuro-img-notebook"
              alt="notebook-img"
            />
            <img
              src="images/shadow-img-centrino.png"
              className="centuro-img-shadow"
              alt="shadow-img"
            />
            <img src="images/centrino-img-header.svg" className="lumina-img-header" alt="centrino-img" />
          </div>
        </div>
      </header>
      <PageSection
        boldTitle={"Centrino."}
        title={"BRAND and WEBSITE DESIGN"}
        paragraph={
          <>
            Cryptocurrency Exchange Development Project<br></br>
            <br></br>
            Our team developed a high-performance cryptocurrency exchange,
            allowing users to trade digital assets securely and efficiently. We
            created the platform with a focus on reliability, speed, and ease of
            use, providing a seamless experience for traders of all levels.
            <br></br>
            <br></br>
            What We Did:<br></br>
            <br></br>
            User-friendly interface — we designed a simple and functional layout
            that makes the trading process convenient and accessible for users,
            regardless of their experience.<br></br>
            <br></br>
            Instant transaction processing — the exchange was built to process
            orders quickly, ensuring minimal delays and high-speed trade
            execution.<br></br>
            <br></br>
            Advanced analytical tools — we integrated analytical tools, charts,
            indicators, and other features to help users make well-informed
            trading decisions.<br></br>
            High level of security — implemented advanced security measures,
            including two-factor authentication, data encryption, and cold
            storage of assets, to protect users and their funds.<br></br>
            Support for multiple cryptocurrencies — the platform supports a wide
            range of digital assets, providing users with flexibility in trading
            and investing.<br></br>
            <br></br>
            Result:<br></br>
            The cryptocurrency exchange has become a reliable and powerful tool
            for trading, providing users with all the necessary features and
            security for a comfortable and safe experience with digital assets.
          </>
        }
        year={"2021"}
        industry={
          <>
            UI/UX Design<br></br>Frontend and Backend<br></br>Information
            Architecture
          </>
        }
        projectDescription={
          <>
            Brand identity<br></br>Web design<br></br>Web Development
          </>
        }
      />
      <ColoursSection colours={colors} />
      <section className="page__section">
        <img
          src="images/qwerty-img.png"
          className="safe-img-qwerty page-img"
          alt="qwerty"
        />
      </section>
      <footer className="page__footer">
        <img
          src="images/centuro-img-group.svg"
          className="centuro-img-group page-img"
          alt="phones"
        />
        <PageFooterButton link="/boostchain" />
      </footer>
      <SecondPartOfFooter />
    </>
  );
};
