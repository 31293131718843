import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

// Функция для плавной прокрутки с учетом фиксированного хедера
const scrollWithOffset = (el: any) => {
  const yOffset = -80; // Учитывает фиксированный хедер
  const y = el.getBoundingClientRect().top + window.scrollY + yOffset;
  window.scrollTo({ top: y, behavior: "smooth" });
};

export const HeaderPanel = () => {
  return (
    <div className="header-container">
      <NavLink to="/" className="page-logo-container">
        <img
          src="images/S-letter.svg"
          className="page-logo page-logo-S"
          alt="logo"
        />
        <img
          src="images/O-letter.svg"
          className="page-logo page-logo-O"
          alt="logo"
        />
        <img
          src="images/B-letter.svg"
          className="page-logo page-logo-B"
          alt="logo"
        />
        <img
          src="images/E-letter.svg"
          className="page-logo page-logo-E"
          alt="logo"
        />
        <img
          src="images/X-letter.svg"
          className="page-logo page-logo-X"
          alt="logo"
        />
      </NavLink>
      <ul className="header__list">
        <li className="header__item">
          <HashLink to="/#about" scroll={scrollWithOffset} className="page__link">
            About us
          </HashLink>
        </li>
        <li className="header__item">
          <HashLink to="/#partners" scroll={scrollWithOffset} className="page__link">
            Our partners
          </HashLink>
        </li>
        <li className="header__item">
          <HashLink to="/#services" scroll={scrollWithOffset} className="page__link">
            Services
          </HashLink>
        </li>
        <li className="header__item">
          <HashLink to="/#our-works" scroll={scrollWithOffset} className="page__link">
            Our works
          </HashLink>
        </li>
        <li className="header__item">
          <HashLink to="/#contacts" scroll={scrollWithOffset} className="page__link">
            Contacts
          </HashLink>
        </li>
      </ul>
    </div>
  );
};
