import { NavLink } from "react-router-dom";
import { ColoursSection } from "../ColoursSection/ColoursSection";
import { PageSection } from "../PageSection/PageSection";
import "./safe.scss";
import { SecondPartOfFooter } from "../SecondPartOfFooter/SecondPartOfFooter";
import { MobileMenu } from "../MobileMenu/MobileMenu";
import { PageListLinks } from "../PageListLinks/PageListLinks";
import { PageFooterButton } from "../PageFooterButton/PageFooterButton";

export const SafePage = () => {
  const colors = [
    {
      color: "#AFAFAF",
      codeName: "LightGrey",
      name: "Light Grey",
      subtitleNames: ["#AFAFAF", "R175, G175, B175"],
      order: "first",
      textColor: "black",
    },
    {
      color: "#007AFF",
      codeName: "Blue",
      name: "Blue",
      subtitleNames: ["#007AFF", "R0, G122, B255"],
      order: "second",
      textColor: "white",
    },
    {
      color: "#6E6E73",
      codeName: "Grey",
      name: "Grey",
      subtitleNames: ["#6E6E73", "R110, G110, B115"],
      order: "third",
      textColor: "white",
    },
    {
      color: "#EAADFF",
      codeName: "LightPink",
      name: "Light Pink",
      subtitleNames: ["#EAADFF", "R234, G173, B255"],
      order: "fourth",
      textColor: "black",
    },
    {
      color: "#FFFFFF",
      codeName: "White",
      name: "White",
      subtitleNames: ["#FFFFFF", "R255, G255, B255"],
      order: "fifth",
      textColor: "black",
    },
    {
      color: "#8751FF",
      codeName: "Purple",
      name: "Purple",
      subtitleNames: ["#8751FF", "R135, G81, B255"],
      order: "sixth",
      textColor: "black",
    },
  ];

  return (
    <>
      <header className="page__header__main-container">
        <div className="page__header-container">
          <MobileMenu />
          <div className="page__header">
            <NavLink to="/" className="page-logo-container">
              <img
                src="images/S-letter.svg"
                className="page-logo page-logo-S"
                alt="logo"
              />
              <img
                src="images/O-letter.svg"
                className="page-logo page-logo-O"
                alt="logo"
              />
              <img
                src="images/B-letter.svg"
                className="page-logo page-logo-B"
                alt="logo"
              />
              <img
                src="images/E-letter.svg"
                className="page-logo page-logo-E"
                alt="logo"
              />
              <img
                src="images/X-letter.svg"
                className="page-logo page-logo-X"
                alt="logo"
              />
            </NavLink>
            <PageListLinks />
          </div>
          <div className="safe__main-container">
            <div className="lumina__title-container">
              <h1 className="greit__title">Safe Wallet</h1>
              <h2 className="shell__subtitle">Investment fund</h2>
            </div>
            <img
              src="images/safe-img-header.svg"
              className="safe-img-header-phone"
              alt="notebook-img"
            />
            <img
              src="images/shadow-img-safe.png"
              className="safe-img-shadow"
              alt="shadow-img"
            />
            <img
              src="images/safe-img-header-mobile.svg"
              className="lumina-img-header safe-img-header"
              style={{ bottom: '260px' }}
              alt="greit-img"
            />
          </div>
        </div>
      </header>
      <PageSection
        boldTitle={"safe wallet."}
        title={"BRAND and APP DESIGN"}
        paragraph={
          <>
            Individual Crypto Wallet Development Project for Private Use
            <br></br>
            <br></br>
            Our team developed a unique crypto wallet designed for private
            users, providing maximum security and ease of use for managing
            digital assets. We focused on creating a modern, user-friendly
            interface that combines reliability with simplicity.<br></br>
            <br></br>
            What We Did:<br></br>
            <br></br>
            Minimalist and intuitive design — we crafted an interface where
            users can easily manage their cryptocurrencies, track balances, and
            perform transactions without unnecessary distractions.<br></br>
            <br></br>
            Focus on security — implemented multi-layered security measures,
            such as biometric authentication and data encryption, to ensure
            maximum protection for users’ assets.<br></br>
            <br></br>
            Real-time transaction tracking — added functionality that allows
            users to instantly view the status of their transactions and
            transaction history, enhancing transparency and ease of use.
            <br></br>
            <br></br>
            Support for multiple cryptocurrencies — the wallet was designed to
            support a variety of digital assets, providing users with
            flexibility in managing their portfolios.<br></br>
            <br></br>
            Result:<br></br>
            The individual crypto wallet has become a reliable tool for private
            users, offering convenient and secure access to their
            cryptocurrencies.
          </>
        }
        year={"2022"}
        industry={
          <>
            UX Research<br></br>App Testing<br></br>Frontend and Backend
          </>
        }
        projectDescription={
          <>
            Brand identity<br></br>Web design<br></br>Web Development
          </>
        }
      />
      <ColoursSection colours={colors} />
      <section className="page__section">
        <img
          src="images/qwerty-img.png"
          className="safe-img-qwerty page-img"
          alt="qwerty"
        />
      </section>
      <footer className="page__footer">
        <div className="safe-img-mobile-container">
          <img src="images/safe-img-mobile.svg" className="safe-img-mobile" alt="safe-img" />
          <img src="images/safe-img-mobile-2.svg" className="safe-img-mobile" alt="safe-img" />
          <img src="images/safe-img-mobile-3.svg" className="safe-img-mobile" alt="safe-img" />

        </div>
        <img
          src="images/phones-safe-group-img.png"
          className="safe-img-group page-img"
          alt="phones"
        />
        <PageFooterButton link="/centrino" />
      </footer>
      <SecondPartOfFooter />
    </>
  );
};
