import { NavLink } from "react-router-dom";
import { ColoursSection } from "../ColoursSection/ColoursSection";
import { PageFooterButton } from "../PageFooterButton/PageFooterButton";
import { PageSection } from "../PageSection/PageSection";
import "./digital.scss";
import { SecondPartOfFooter } from "../SecondPartOfFooter/SecondPartOfFooter";
import { MobileMenu } from "../MobileMenu/MobileMenu";
import { PageListLinks } from "../PageListLinks/PageListLinks";
// import "./merchain.scss";
// import "./match.scss";

export const DigitalPage = () => {
  const colors = [
    {
      color: "#1C1C1C",
      codeName: "Nero",
      name: "Nero",
      subtitleNames: ["#1C1C1C", "R28, G28, B28"],
      order: "first",
      textColor: "white",
    },
    {
      color: "#3686FF",
      codeName: "DodgerBlue",
      name: "Dodger Blue",
      subtitleNames: ["#3686FF", "R54, G134, B255"],
      order: "second",
      textColor: "white",
    },
    {
      color: "#151515",
      codeName: "Vulcan",
      name: "Vulcan",
      subtitleNames: ["#151515", "R21, G21, B21"],
      order: "third",
      textColor: "white",
    },
    {
      color: "#A295F6",
      codeName: "Wistful",
      name: "Wistful",
      subtitleNames: ["#A295F6", "R162, G149, B246"],
      order: "fourth",
      textColor: "black",
    },
    {
      color: "#FFFFFF",
      codeName: "White",
      name: "White",
      subtitleNames: ["#FFFFFF", "R255, G255, B255"],
      order: "fifth",
      textColor: "black",
    },
    {
      color: "#2A2837",
      codeName: "Haiti",
      name: "Haiti",
      subtitleNames: ["#2A2837", "R42, G40, B55"],
      order: "sixth",
      textColor: "white",
    },
  ];

  return (
    <>
      <header className="page__header__main-container">
        <div className="page__header-container">
          <MobileMenu />
          <div className="page__header">
            <NavLink to="/" className="page-logo-container">
              <img
                src="images/S-letter.svg"
                className="page-logo page-logo-S"
                alt="logo"
              />
              <img
                src="images/O-letter.svg"
                className="page-logo page-logo-O"
                alt="logo"
              />
              <img
                src="images/B-letter.svg"
                className="page-logo page-logo-B"
                alt="logo"
              />
              <img
                src="images/E-letter.svg"
                className="page-logo page-logo-E"
                alt="logo"
              />
              <img
                src="images/X-letter.svg"
                className="page-logo page-logo-X"
                alt="logo"
              />
            </NavLink>
            <PageListLinks />
          </div>
          <div className="safe__main-container">
            <div className="lumina__title-container">
              <h1
                className="greit__title centuro__title"
                style={{
                  fontSize: window.innerWidth <= 768 ? "65px" : "100px",
                }}
              >
                DigitalAssetDealer
              </h1>
              <h2 className="shell__subtitle">DigitalAssetDealer</h2>
            </div>
            <img
              src="images/digital-notebook.svg"
              className="centuro-img-notebook"
              alt="notebook-img"
            />
            <img
              src="images/shadow-img-digital.png"
              className="merchain-img-shadow"
              alt="shadow-img"
            />
            <img
              src="images/digital-img-header.svg"
              className="lumina-img-header"
              alt="digital-img"
            />
          </div>
        </div>
      </header>
      <PageSection
        boldTitle={"DigitalAssetDealer."}
        title={"BRAND and WEBSITE"}
        secondTitle={"DESIGN"}
        paragraph={
          <>
            Development Project for One of the Largest P2P Platforms and
            Exchange Services<br></br>
            <br></br>
            Our team developed a robust P2P platform, designed to serve as a
            leading exchange service connecting individuals and businesses. This
            platform facilitates seamless transactions and currency exchanges
            between users while providing high security and transparency.
            <br></br>
            <br></br>
            What We Did:<br></br>
            <br></br>
            Scalable and User-Friendly Design — created a modern, intuitive
            interface to ensure seamless navigation for both individuals and
            businesses, catering to various levels of experience.<br></br>
            <br></br>
            P2P Transaction System — implemented a secure and efficient system
            that enables direct transactions between users, ensuring
            transparency and reliability throughout the process.<br></br>
            <br></br>
            Integration of Multi-Currency Support — supported a wide range of
            fiat and cryptocurrencies to provide users with maximum flexibility
            in their exchanges and payments.<br></br>
            <br></br>
            Advanced Security Measures — integrated KYC/AML compliance
            protocols, multi-factor authentication, and fraud detection systems
            to protect user data and funds.<br></br>
            <br></br>
            User and Business Management — developed tools to manage accounts
            for both individual and corporate users, including features for
            identity verification, transaction history, and customizable user
            settings.<br></br>
            <br></br>
            Real-Time Exchange Rates — built a dynamic system to display and
            update exchange rates in real-time, ensuring accuracy and
            competitiveness for users.<br></br>
            <br></br>
            Comprehensive Analytics and Reporting — provided tools for
            generating detailed reports on platform activity, transaction
            volume, and user engagement to help administrators optimize platform
            performance.<br></br>
            <br></br>
            Result:<br></br>
            The P2P platform has become one of the largest and most reliable
            exchange services, bridging the gap between individual and corporate
            users. Its secure infrastructure, user-friendly design, and advanced
            transaction features position it as a key player in the exchange
            market, offering efficient and transparent services to its growing
            user base.
          </>
        }
        year={"2024"}
        industry={
          <>
            Product Design<br></br>UI/UX Design<br></br>B2B and B2C Platforms
            <br></br>Payment Systems and Security<br></br>Compliance and KYC
            Solutions
          </>
        }
        projectDescription={
          <>
            Global P2P exchange connecting individuals and businesses<br></br>
            Providing a secure platform for currency and asset trading<br></br>
            Enhancing user trust through compliance and transparent operations
          </>
        }
      />
      <ColoursSection colours={colors} />
      <img
        src="images/qwerty-img.png"
        className="page-img digital-img-qwerty"
        alt="qwerty"
      />
      <footer className="page__footer">
        <div className="digital-img-notebooks-container">
          <img
            src="images/digital-notebooks-footer.png"
            className="digital-img-notebooks page-img"
            alt="notebooks"
          />
        </div>
        \
        <PageFooterButton link="/blinkswap" />
      </footer>
      <SecondPartOfFooter />
    </>
  );
};
