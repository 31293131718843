import { NavLink } from "react-router-dom";
import { ColoursSection } from "../ColoursSection/ColoursSection";
import { PageFooterButton } from "../PageFooterButton/PageFooterButton";
import { PageSection } from "../PageSection/PageSection";
import "./crypto.scss";
import { SecondPartOfFooter } from "../SecondPartOfFooter/SecondPartOfFooter";
import { MobileMenu } from "../MobileMenu/MobileMenu";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-scroll";
import { PageListLinks } from "../PageListLinks/PageListLinks";

export const CryptoPage = () => {
  const colors = [
    {
      color: "#06254A",
      codeName: "DarkBlue",
      name: "Dark Blue",
      subtitleNames: ["#06254A", "R6, G37, B74"],
      order: "first",
      textColor: "white",
    },
    {
      color: "#1472FE",
      codeName: "Blue",
      name: "Blue",
      subtitleNames: ["#1472FE", "R20, G114, B254"],
      order: "second",
      textColor: "white",
    },
    {
      color: "#33314E",
      codeName: "Paua",
      name: "Paua",
      subtitleNames: ["#33314E", "R51, G49, B78"],
      order: "third",
      textColor: "white",
    },
    {
      color: "#813D6D",
      codeName: "Violet",
      name: "Violet",
      subtitleNames: ["#813D6D", "R129, G61, B109"],
      order: "fourth",
      textColor: "white",
    },
    {
      color: "#FFFFFF",
      codeName: "White",
      name: "White",
      subtitleNames: ["#FFFFFF", "R255, G255, B255"],
      order: "fifth",
      textColor: "black",
    },
    {
      color: "#F7357D",
      codeName: "Fuxia",
      name: "Fuxia",
      subtitleNames: ["#F7357D", "R247, G53, B125"],
      order: "sixth",
      textColor: "white",
    },
  ];

  return (
    <>
      <header className="page__header__main-container">
        <div className="page__header-container">
          <MobileMenu />
          <div className="page__header">
            <NavLink to="/" className="page-logo-container">
              <img
                src="images/S-letter.svg"
                className="page-logo page-logo-S"
                alt="logo"
              />
              <img
                src="images/O-letter.svg"
                className="page-logo page-logo-O"
                alt="logo"
              />
              <img
                src="images/B-letter.svg"
                className="page-logo page-logo-B"
                alt="logo"
              />
              <img
                src="images/E-letter.svg"
                className="page-logo page-logo-E"
                alt="logo"
              />
              <img
                src="images/X-letter.svg"
                className="page-logo page-logo-X"
                alt="logo"
              />
            </NavLink>
            <PageListLinks />
          </div>
          <div className="safe__main-container">
            <div className="lumina__title-container">
              <h1
                className="greit__title centuro__title crypto__title"
                style={{
                  fontSize: window.innerWidth <= 768 ? "80px" : "165px",
                }}
              >
                CryptoMart
              </h1>
              <h2 className="shell__subtitle">Crypto store </h2>
            </div>
            <img
              src="images/boost-notebook-header.png"
              className="centuro-img-notebook"
              alt="notebook-img"
            />
            <img
              src="images/boost-shadow.png"
              className="centuro-img-shadow"
              alt="shadow-img"
            />
            <img
              src="images/crypto-img-header.svg"
              className="lumina-img-header"
              alt="crypto-img"
            />
          </div>
        </div>
      </header>
      <PageSection
        boldTitle={"Crypto store."}
        title={"BRAND and WEBSITE"}
        secondTitle={"DESIGN"}
        paragraph={
          <>
            Crypto store Platform Development Project<br></br>
            <br></br>
            Our team developed an innovative e-commerce platform, INM Store,
            which allows users to purchase goods and services with the added
            benefit of paying a portion of the amount in INM tokens. The
            platform includes a user-friendly interface that facilitates a
            seamless shopping experience, integrating cryptocurrency payments to
            enhance flexibility and customer engagement.<br></br>
            <br></br>
            What We Did:<br></br>
            <br></br>
            Hybrid Payment System — developed a system that allows users to pay
            partially in INM tokens, giving customers more flexibility and
            incentivizing the use of the platform’s native token.<br></br>
            <br></br>
            User-Friendly Interface — designed an intuitive layout to make it
            easy for users to browse, add items to the cart, and proceed with
            purchases. The clean design ensures smooth navigation and quick
            access to all necessary functions.<br></br>
            <br></br>
            Personal Profile and Balance Management — created a personal profile
            section where users can view their INM and USD balances, connect to
            external platforms, and manage their funds effortlessly.<br></br>
            <br></br>
            Cart and Checkout Optimization — streamlined the cart and checkout
            process, allowing users to see the total amount in USD and INM
            tokens, and providing a clear path to finalize their purchases.
            <br></br>
            <br></br>
            Integrated Rewards — implemented a “Spin and Win” feature, allowing
            users to try their luck for additional prizes, thus enhancing
            engagement and providing an interactive shopping experience.
            <br></br>
            <br></br>
            Result:<br></br>
            The INM Store has become a versatile e-commerce platform that
            combines traditional and crypto payments, offering users a unique
            and engaging shopping experience. The project not only promotes the
            use of INM tokens but also provides an enjoyable and interactive way
            to shop for a wide range of products.
          </>
        }
        year={"2024"}
        industry={
          <>
            UI/UX Design<br></br>Hybrid Payment System Integration<br></br>
            Personal Profile Management<br></br>Rewards and Incentives System
            <br></br>Cart and Checkout Optimization
          </>
        }
        projectDescription={
          <>
            Building an e-commerce platform combining traditional and crypto
            payments<br></br>Promoting the use of native tokens through flexible
            payment options<br></br>Enhancing user experience with gamified
            shopping features
          </>
        }
      />
      <ColoursSection colours={colors} />
      <img src="images/qwerty-img.png" className="page-img" alt="qwerty" />
      <img
        src="images/qwerty-img-2.png"
        className="boost-img-qwerty page-img"
        alt="qwerty"
      />
      <img
        src="images/crypto-img-phones.png"
        className="page-img crypto-img-phones"
        alt="phones"
      />
      <div className="shell-img-mobile-container">
        <img
          src="images/crypto-img-1.svg"
          className="shell-img-mobile"
          alt="shell-img"
        />
        <img
          src="images/crypto-img-2.svg"
          className="shell-img-mobile"
          alt="shell-img"
        />
        <img
          src="images/crypto-img-3.svg"
          className="shell-img-mobile"
          alt="shell-img"
        />
      </div>
      <footer className="page__footer">
        <img
          src="images/crypto-notebooks.png"
          className="page-img crypto-img-notebooks"
          alt="notebooks"
        />

        <PageFooterButton link="/inmatch" />
      </footer>
      <SecondPartOfFooter />
    </>
  );
};
