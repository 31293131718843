import React, { useState, useEffect } from "react";
import "./form.scss";
import '../../styles/page.scss';

export const Form = () => {
  const [telegram, setTelegram] = useState("");

  useEffect(() => {
    // Clean submissions older than 24 hours
    const submissions = JSON.parse(localStorage.getItem('submissions') || '[]');
    const now = Date.now();
    const filteredSubmissions = submissions.filter(
      (timestamp: number) => now - timestamp < 24 * 60 * 60 * 1000
    );
    localStorage.setItem('submissions', JSON.stringify(filteredSubmissions));
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!telegram.trim()) {
      window.alert("Please enter a valid Telegram username.");
      return;
    }

    // Retrieve and filter submission attempts.
    let submissions = JSON.parse(localStorage.getItem('submissions') || '[]');
    if (submissions.length >= 3) {
      window.alert("Submission limit reached. Try again in 24 hours.");
      return;
    }

    try {
      const response = await fetch(
        "https://web.sobex.agency/api/send-telegram",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ telegram }),
        }
      );

      if (response.ok) {
        window.alert("Message sent successfully!");
        setTelegram("");

        // Update submissions
        submissions.push(Date.now());
        localStorage.setItem('submissions', JSON.stringify(submissions));
      } else {
        const errorData = await response.json();
        window.alert(`Error: ${errorData.message || "Something went wrong."}`);
      }
    } catch (error: any) {
      window.alert(`Error: ${error.message}`);
    }
  };

  return (
    <form id="form" className="page__section form" onSubmit={handleSubmit}>
      <h2 className="form__title">We look forward to connecting with you!</h2>
      <div className="form__field-container">
        <input
          className="form__field"
          placeholder="@telegram_nickname"
          value={telegram}
          onChange={(e) => setTelegram(e.target.value)}
        />
        <button className="form__button" type="submit">
          Submit
        </button>
      </div>
    </form>
  );
};