import { NavLink, useLocation } from "react-router-dom";
import "../../styles/page.scss";
import { useState } from "react";
import { Link } from "react-scroll";
import { HashLink } from "react-router-hash-link";

export const MobileMenu = () => {
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();

  const handleOpenMenu = () => {
    setIsActive(!isActive);
  };

  return (
    <>
      <div
        className={`page-logo__main-container ${
          location.pathname === "/" ? "page-logo__main-container-mobile" : ""
        }`}
      >
        <NavLink
          to="/"
          className="page-logo-container page-logo-container-mobile"
        >
          <img
            src="images/S-letter.svg"
            className="page-logo page-logo-S"
            alt="logo"
          />
          <img
            src="images/O-letter.svg"
            className="page-logo page-logo-O"
            alt="logo"
          />
          <img
            src="images/B-letter.svg"
            className="page-logo page-logo-B"
            alt="logo"
          />
          <img
            src="images/E-letter.svg"
            className="page-logo page-logo-E"
            alt="logo"
          />
          <img
            src="images/X-letter.svg"
            className="page-logo page-logo-X"
            alt="logo"
          />
        </NavLink>
        <button className="page__button-burger" onClick={handleOpenMenu}>
          <img
            src="images/hamburger.svg"
            className="page__button-burger-img"
            alt="hamburger"
          />
        </button>
      </div>
      {isActive && (
        <>
          <div className="page__menu">
            <div>
              <NavLink
                to="/"
                className="page-logo-container page-logo-container-mobile__menu"
                onClick={handleOpenMenu}
              >
                <img
                  src="images/S-letter.svg"
                  className="page-logo page-logo-S"
                  alt="logo"
                />
                <img
                  src="images/O-letter.svg"
                  className="page-logo page-logo-O"
                  alt="logo"
                />
                <img
                  src="images/B-letter.svg"
                  className="page-logo page-logo-B"
                  alt="logo"
                />
                <img
                  src="images/E-letter.svg"
                  className="page-logo page-logo-E"
                  alt="logo"
                />
                <img
                  src="images/X-letter.svg"
                  className="page-logo page-logo-X"
                  alt="logo"
                />
              </NavLink>
              <button className="page__button-cross" onClick={handleOpenMenu}>
                <img
                  src="images/cross-out-mark.svg"
                  className="page__button-cross-img"
                  alt="cross"
                />
              </button>
            </div>
            <ul className="page__list-mobile">
              <li className="page__item">
                {/* <Link
                  to="about"
                  smooth={true}
                  duration={500}
                  className="page__link"
                  onClick={handleOpenMenu}
                >
                  About us
                </Link> */}
                <HashLink to="/#about" smooth={true} className="page__link">
                   About us
                </HashLink>
              </li>
              <li className="page__item">
                {/* <Link
                  to="partners"
                  smooth={true}
                  duration={500}
                  className="page__link"
                  onClick={handleOpenMenu}
                >
                  Our partners
                </Link> */}
                <HashLink to="/#partners" smooth={true} className="page__link">
                  Our partners
                </HashLink>
              </li>
              <li className="page__item">
                {/* <Link
                  to="services"
                  smooth={true}
                  duration={500}
                  className="page__link"
                  onClick={handleOpenMenu}
                >
                  Services
                </Link> */}
                <HashLink to="/#services" smooth={true} className="page__link">
                  Services
                </HashLink>
              </li>
              <li className="page__item">
                {/* <Link
                  to="our-works"
                  smooth={true}
                  duration={500}
                  className="page__link"
                  onClick={handleOpenMenu}
                >
                  Our works
                </Link> */}
                <HashLink
                  to="/#our-works"
                  smooth={true}
                  className="page__link"
                >
                  Our works
                </HashLink>
              </li>
              <li className="page__item">
                {/* <Link
                  to="contacts"
                  smooth={true}
                  duration={500}
                  className="page__link"
                  onClick={handleOpenMenu}
                >
                  Contacts
                </Link> */}
                <HashLink to="/#contacts" smooth={true} className="page__link">
                  Contacts
                </HashLink>
              </li>
            </ul>
          </div>
        </>
      )}
    </>
  );
};
