import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useHashScroll = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);

      if (element) {
        const prefersReducedMotion = window.matchMedia(
          "(prefers-reduced-motion: reduce)"
        ).matches;

        setTimeout(() => {
          if (prefersReducedMotion) {
            element.scrollIntoView({ behavior: "auto", block: "start" });
          } else {
            requestAnimationFrame(() => {
              element.scrollIntoView({ behavior: "smooth", block: "start" });
            });
          }
        }, 100); // Важно оставить небольшую задержку
      }
    }
  }, [location]);
};
