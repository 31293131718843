import { NavLink } from "react-router-dom";
import { ColoursSection } from "../ColoursSection/ColoursSection";
import { PageFooterButton } from "../PageFooterButton/PageFooterButton";
import { PageSection } from "../PageSection/PageSection";
import "./blinkswap.scss";
import { SecondPartOfFooter } from "../SecondPartOfFooter/SecondPartOfFooter";
import { MobileMenu } from "../MobileMenu/MobileMenu";
import { PageListLinks } from "../PageListLinks/PageListLinks";

export const BlinkswapPage = () => {
  const colors = [
    {
      color: "#C7F782",
      codeName: "Sulu",
      name: "Sulu",
      subtitleNames: ["#C7F782", "R199, G255, B130"],
      order: "first",
      textColor: "black",
    },
    {
      color: "#F4BEC5",
      codeName: "Azalea",
      name: "Azalea",
      subtitleNames: ["#F4BEC5", "R244, G190, B197"],
      order: "second",
      textColor: "black",
    },
    {
      color: "#151515",
      codeName: "Vulcan",
      name: "Vulcan",
      subtitleNames: ["#151515", "R21, G21, B21"],
      order: "third",
      textColor: "white",
    },
    {
      color: "#A295F6",
      codeName: "Wistful",
      name: "Wistful",
      subtitleNames: ["#A295F6", "R162, G149, B246"],
      order: "fourth",
      textColor: "black",
    },
    {
      color: "#FFFFFF",
      codeName: "White",
      name: "White",
      subtitleNames: ["#FFFFFF", "R255, G255, B255"],
      order: "fifth",
      textColor: "black",
    },
    {
      color: "#656970",
      codeName: "ShuttleGrey",
      name: "Shuttle Grey",
      subtitleNames: ["#656970", "R101, G105, B112"],
      order: "sixth",
      textColor: "white",
    },
  ];

  return (
    <>
      <header className="page__header__main-container">
        <div className="page__header-container">
          <MobileMenu />
          <div className="page__header">
            <NavLink to="/" className="page-logo-container">
              <img
                src="images/S-letter.svg"
                className="page-logo page-logo-S"
                alt="logo"
              />
              <img
                src="images/O-letter.svg"
                className="page-logo page-logo-O"
                alt="logo"
              />
              <img
                src="images/B-letter.svg"
                className="page-logo page-logo-B"
                alt="logo"
              />
              <img
                src="images/E-letter.svg"
                className="page-logo page-logo-E"
                alt="logo"
              />
              <img
                src="images/X-letter.svg"
                className="page-logo page-logo-X"
                alt="logo"
              />
            </NavLink>
            <PageListLinks />
          </div>
          <div className="safe__main-container">
            <div className="lumina__title-container">
              <h1
                className="greit__title centuro__title"
                style={{
                  fontSize: window.innerWidth <= 768 ? "80px" : "170px",
                }}
              >
                Blinkswap
              </h1>
              <h2 className="shell__subtitle">Crypto-trading company</h2>
            </div>
            <img
              src="images/blinkswap-img.svg"
              className="centuro-img-notebook"
              alt="notebook-img"
            />
            <img
              src="images/shadow-img-digital.png"
              className="merchain-img-shadow"
              alt="shadow-img"
            />
            <img
              src="images/blinkswap-img-header.svg"
              className="lumina-img-header"
              alt="blinkswap-img"
            />
          </div>
        </div>
      </header>
      <PageSection
        boldTitle={"bLINKSWAP."}
        title={"BRAND and WEBSITE"}
        secondTitle={"DESIGN"}
        paragraph={
          <>
            Development Project for a Multifunctional Admin Panel for Exchange
            and Cryptocurrency Services<br></br>
            <br></br>
            Our team developed a multifunctional admin panel designed to
            streamline the management of exchange services and cryptocurrency
            trading platforms. This solution provides administrators with the
            tools to oversee operations efficiently, monitor transactions in
            real-time, and ensure seamless functionality for end-users.<br></br>
            <br></br>
            What We Did:<br></br>
            <br></br>
            Comprehensive Dashboard — designed an intuitive and user-friendly
            interface that consolidates key metrics, transaction monitoring, and
            operational controls in a single dashboard.<br></br>
            <br></br>
            Real-Time Transaction Monitoring — implemented tools to track
            transactions across multiple currencies and services, providing
            administrators with immediate insights into platform activity.
            <br></br>
            <br></br>
            User Management System — developed robust tools for managing user
            accounts, including registration, verification, and activity
            monitoring, ensuring compliance with KYC/AML standards.<br></br>
            <br></br>
            Exchange Rate Management — created a system that enables
            administrators to set, monitor, and adjust exchange rates
            dynamically based on market conditions.<br></br>
            <br></br>
            Security and Fraud Detection — integrated advanced security
            protocols and automated fraud detection systems to safeguard
            transactions and user data.<br></br>
            <br></br>
            API Integration and Customization — enabled seamless integration
            with third-party services and provided customizable APIs to adapt
            the panel to specific business needs.<br></br>
            <br></br>
            Detailed Reporting and Analytics — implemented reporting tools to
            generate detailed performance and financial reports, helping
            administrators make informed decisions.<br></br>
            <br></br>
            Result:<br></br>
            The multifunctional admin panel has become an essential tool for
            managing exchange services and cryptocurrency trading platforms. Its
            combination of real-time monitoring, robust security features, and
            customizable options ensures efficient and reliable operation,
            making it a critical asset for maintaining platform integrity and
            performance.
          </>
        }
        year={"2024"}
        industry={
          <>
            Product Design<br></br>UI/UX Design<br></br>User Management
            <br></br>Analytics and Monitoring<br></br>Frontend and Backend
          </>
        }
        projectDescription={
          <>
            Comprehensive administration tool for cryptocurrency platforms
            <br></br>
            Real-time transaction monitoring and user management<br></br>
            Streamlining operational efficiency and security
          </>
        }
      />
      <ColoursSection colours={colors} />
      <img
        src="images/qwerty-img.png"
        className="page-img digital-img-qwerty"
        alt="qwerty"
      />
      <footer className="page__footer" style={{ marginTop: "127px" }}>
        <div style={{ marginBottom: "500px" }}>
          <img
            src="images/mockup-img.png"
            className="blinkswap-img-mockup"
            alt="mockup"
          />
          <div className="blinkswap-img-notebooks-container">
            <img
              src="images/blinkswap-notebooks.svg"
              className="blinkswap-img-notebooks page-img"
              alt="notebooks"
            />
          </div>
        </div>

        <PageFooterButton link="/meta-verified" />
      </footer>
      <SecondPartOfFooter />
    </>
  );
};
