import { HashLink } from "react-router-hash-link";

const smoothScroll = (targetY: number, duration = 500) => {
  const startY = window.scrollY;
  const diff = targetY - startY;
  let startTime: number | null = null;

  const step = (timestamp: number) => {
    if (!startTime) startTime = timestamp;
    const progress = Math.min((timestamp - startTime) / duration, 1);
    window.scrollTo(0, startY + diff * easeInOutQuad(progress));
    if (progress < 1) requestAnimationFrame(step);
  };

  const easeInOutQuad = (t: number) =>
    t < 0.5 ? 2 * t * t : 1 - Math.pow(-2 * t + 2, 2) / 2;

  requestAnimationFrame(step);
};

const scrollWithOffset = (el: HTMLElement) => {
  const yOffset = -80; // Отступ сверху (например, если есть фиксированный header)
  const y = el.getBoundingClientRect().top + window.scrollY + yOffset;

  const prefersReducedMotion = window.matchMedia(
    "(prefers-reduced-motion: reduce)"
  ).matches;

  if (prefersReducedMotion) {
    window.scrollTo(0, y);
  } else {
    smoothScroll(y);
  }
};

export const PageListLinks = () => {
  return (
    <ul className="page__list">
      <li className="page__item">
        <HashLink to="/#about" scroll={scrollWithOffset} className="page__link">
          About us
        </HashLink>
      </li>
      <li className="page__item">
        <HashLink to="/#partners" scroll={scrollWithOffset} className="page__link">
          Our partners
        </HashLink>
      </li>
      <li className="page__item">
        <HashLink to="/#services" scroll={scrollWithOffset} className="page__link">
          Services
        </HashLink>
      </li>
      <li className="page__item">
        <HashLink to="/#our-works" scroll={scrollWithOffset} className="page__link">
          Our works
        </HashLink>
      </li>
      <li className="page__item">
        <HashLink to="/#contacts" scroll={scrollWithOffset} className="page__link">
          Contacts
        </HashLink>
      </li>
    </ul>
  );
};
