import "./main.scss";
import "../../styles/page.scss";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { desingCards } from "../../utils/base";
import { ServiceCard } from "../serviceCard/serviceCard";
import { useInView } from "react-intersection-observer";
import { Link } from "react-scroll";
import { useHashScroll } from "../../utils/hooks";

export const MainPage = () => {
  // const { ref: titleRef, inView: titleInView } = useInView({
  //   threshold: 0.1,
  //   triggerOnce: true,
  // });
  const { ref: titleSecondRef, inView: titleSecondInView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });
  const { ref: servicesTitleRef, inView: servicesTitleInView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  useHashScroll();

  return (
    <>
      <Header />
      <section className="page__section main__section">
        <div className="main__title-mobile-container">
          <h2
            // ref={titleRef}
            className="main__title-mobile-main"
          >
            As a global
          </h2>
          <h2
            // ref={titleSecondRef}
            className="main__title-mobile"
          >
            agency
          </h2>
        </div>
        <h2
          // ref={titleRef}
          // className={`main__title ${titleInView ? "visible" : ""}`}
          className="main__title"
        >
          As a global
        </h2>
        <div className="main-container">
          <h2
            // ref={titleSecondRef}
            // className={`main__title-second ${
            //   titleSecondInView ? "visible" : ""
            // }`}
            className="main__title-second"
          >
            agency
          </h2>
          <p className="page__subtitle main__subtitle">
            We enhance the personal and professional lives of our clients and
            team by growing our clients' businesses online. We provide experts
            for your projects, allowing you to keep your business project under
            control and work with us within your company.
          </p>
        </div>
        <div className="main-advantages__main-container">
          <div className="main-advantages-container main-advantages-container-first">
            <h2 className="main-advantages__title">100+</h2>
            <p className="main-advantages__subtitle">
              successful projects for clients worldwide
            </p>
          </div>
          <div className="main-advantages-container main-advantages-container-second">
            <h2 className="main-advantages__title">15+</h2>
            <p className="main-advantages__subtitle">countries of operation</p>
          </div>
          <div className="main-advantages-container main-advantages-container-third">
            <h2 className="main-advantages__title">27+</h2>
            <p className="main-advantages__subtitle">experts on the team</p>
          </div>
          <div className="main-advantages-container main-advantages-container-fourth">
            <h2 className="main-advantages__title">7</h2>
            <p className="main-advantages__subtitle">years in industry</p>
          </div>
          <div className="main-advantages-container main-advantages-container-fifth">
            <h2 className="main-advantages__title">98%</h2>
            <p className="main-advantages__subtitle">satisfied clients</p>
          </div>
        </div>
      </section>
      <section id="partners" className="page__section join">
        <div className="join__main-container">
          <h2 className="join__title join__title-desktop">
            Join Us and <br></br> and Earn <br></br> with Us!
          </h2>
          <div>
            <h2 className="join__title join__title-mobile">Join Us and Earn</h2>
            <div style={{ display: "flex" }}>
              <h2 className="join__title join__title-mobile">with Us!</h2>
              {/* <div className="join-partners-container-mobile"> */}
              <img
                src="images/partners.svg"
                className="join-img-mobile"
                alt="partners-img"
              />
              {/* </div> */}
            </div>
          </div>

          <div className="join-container">
            <Link
              to="form"
              smooth={true}
              duration={1000}
              className="join__button page__button page__button-secondary"
            >
              View <br></br> details
            </Link>
            <p className="page__subtitle">
              Collaborate with us as an influencer! Bring clients for design and
              development — we’ll handle the project, and you’ll earn bonuses.
            </p>
            <div className="join-partners-container">
              <img
                src="images/partners.png"
                className="join-img"
                alt="partners-img"
              />
            </div>
          </div>
        </div>
      </section>
      <section style={{ position: "relative" }}>
        <h2
          ref={servicesTitleRef}
          className={`page-colours__title-large-main page-colours__title-large ${
            servicesTitleInView ? "animate" : ""
          }`}
          // style={{ top: "-450px" }}
        >
          Services
        </h2>
      </section>
      <section id="services" className="page__section services">
        <div className="services__main-container">
          {desingCards.map((card) => (
            <ServiceCard key={card.id} card={card} />
          ))}
        </div>
      </section>
      <Footer />
    </>
  );
};
