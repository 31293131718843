import { NavLink } from "react-router-dom";
import { PageFooterButton } from "../PageFooterButton/PageFooterButton";
import { PageSection } from "../PageSection/PageSection";
import "./boost.scss";
import { SecondPartOfFooter } from "../SecondPartOfFooter/SecondPartOfFooter";
import { MobileMenu } from "../MobileMenu/MobileMenu";
import { PageListLinks } from "../PageListLinks/PageListLinks";
import { useInView } from "react-intersection-observer";

export const BoostchainPage = () => {
  const colors = [
    {
      color: "#4A4A4A",
      codeName: "Grey",
      name: "Grey",
      subtitleNames: ["#4A4A4A", "R74, G74, B74"],
      order: "first",
      textColor: "white",
    },
    {
      color: "#FF4D00",
      codeName: "Orange",
      name: "Orange",
      subtitleNames: ["#FF4D00", "R255, G77, B0"],
      order: "second",
      textColor: "black",
    },
    {
      color: "#000000",
      codeName: "Black",
      name: "Black",
      subtitleNames: ["#000000", "R0, G0, B0"],
      order: "third",
      textColor: "white",
    },
    {
      color: "#FFFFFF",
      codeName: "White",
      name: "White",
      subtitleNames: ["#FFFFFF", "R255, G255, B255"],
      order: "fourth",
      textColor: "black",
    },
  ];

  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <>
      <header className="page__header__main-container">
        <div className="page__header-container">
          <MobileMenu />
          <div className="page__header">
            <NavLink to="/" className="page-logo-container">
              <img
                src="images/S-letter.svg"
                className="page-logo page-logo-S"
                alt="logo"
              />
              <img
                src="images/O-letter.svg"
                className="page-logo page-logo-O"
                alt="logo"
              />
              <img
                src="images/B-letter.svg"
                className="page-logo page-logo-B"
                alt="logo"
              />
              <img
                src="images/E-letter.svg"
                className="page-logo page-logo-E"
                alt="logo"
              />
              <img
                src="images/X-letter.svg"
                className="page-logo page-logo-X"
                alt="logo"
              />
            </NavLink>
            <PageListLinks />
          </div>
          <div className="safe__main-container">
            <div className="lumina__title-container">
              <h1
                className="greit__title centuro__title boost__title"
                style={{
                  fontSize: window.innerWidth <= 768 ? "80px" : "165px",
                }}
              >
                Boostchain
              </h1>
              <h2 className="shell__subtitle">Crypto accelerator </h2>
            </div>
            <img
              src="images/boost-notebook-header.png"
              className="centuro-img-notebook"
              alt="notebook-img"
            />
            <img
              src="images/boost-shadow.png"
              className="centuro-img-shadow"
              alt="shadow-img"
            />
            <img
              src="images/boot-img-header.svg"
              className="lumina-img-header"
              alt="boots-img"
            />
          </div>
        </div>
      </header>
      <PageSection
        boldTitle={"Boostchain."}
        title={"BRAND and WEBSITE DESIGN"}
        paragraph={
          <>
            BoostChain – Your Ultimate Crypto Accelerator<br></br>
            <br></br>
            BoostChain is a powerful tool designed to streamline your journey in
            the world of cryptocurrency. Through its innovative Telegram bot and
            user-friendly referral system, BoostChain enables you to earn
            BoostCoins (the platform’s digital tokens) by inviting friends and
            participating in exclusive giveaways and airdrops.<br></br>
            <br></br>
            What We Offer:<br></br>
            <br></br>
            Intuitive Referral System — Invite friends through your referral
            link and earn BoostCoins. The more friends you invite, the more
            rewards and opportunities you unlock.<br></br>
            <br></br>
            Integrated Marketplace — Access current projects and games that
            offer even more ways to earn and keep up with the latest crypto
            trends.<br></br>
            <br></br>
            Bonus Levels and Challenges — Earn additional rewards for reaching
            certain milestones, making participation even more engaging and
            rewarding.<br></br>
            <br></br>
            Real Rewards — BoostChain not only allows you to collect virtual
            bonuses but also lets you participate in major giveaways and
            airdrops with real prizes.<br></br>
            <br></br>
            Result: BoostChain is a simple and exciting way to dive deeper into
            the world of cryptocurrency by inviting friends and accumulating
            valuable rewards. Our tool helps users discover new projects while
            providing all the necessary features for safe and enjoyable
            engagement in the crypto ecosystem.
          </>
        }
        year={"2024"}
        industry={
          <>
            Product Design<br></br>Cross-platform Development<br></br>SEO
            Optimization
          </>
        }
        projectDescription={
          <>
            Brand identity<br></br>Web design<br></br>Web Development<br></br>
            Cryptocurrency Solutions
          </>
        }
      />
      <section className="page-colours__title-img-container">
        <h2
          ref={ref}
          className={`page-colours__title-large ${inView ? "animate" : ""}`}
        >
          Colours
        </h2>
      </section>
      <section className="page-colours">
        <div
          className="page-colours__main-container-secondary"
        >
          {colors.map((color) => (
            <div
              className={`page-colours-container page-colours-container-other-variant-${color.order}`}
              style={{ backgroundColor: color.color }}
            >
              <h2
                className="page-colours__title"
                style={{ color: color.textColor }}
              >
                {color.name}
              </h2>
              <div className="page-colours__subtitle-container">
                <h3
                  className="page-colours__subtitle"
                  style={{ color: color.textColor }}
                >
                  {color.color}
                </h3>
                {color.subtitleNames.slice(1).map((subtitleName) => (
                  <h3
                    className="page-colours__subtitle"
                    style={{ color: color.textColor }}
                  >
                    {subtitleName}
                  </h3>
                ))}
              </div>
            </div>
          ))}
        </div>
      </section>
      <img src="images/qwerty-img.png" className="page-img" alt="qwerty" />
      <img
        src="images/qwerty-img-2.png"
        className="boost-img-qwerty page-img"
        alt="qwerty"
      />
      <img
        src="images/boost-phones-group.png"
        className="boost-img-phones page-img"
        alt="phones"
      />
      <div className="boost-img-mobile-container">
        <img
          src="images/boots-img-1.svg"
          className="boost-img-mobile"
          alt="boots-img"
        />
        <img
          src="images/boots-img-2.svg"
          className="boost-img-mobile"
          alt="boots-img"
        />
        <img
          src="images/boots-img-3.svg"
          className="boost-img-mobile"
          alt="boots-img"
        />
      </div>
      <footer className="page__footer">
        <div className="boost-img-notebooks-container">
          <img
            src="images/boost-notebooks.svg"
            className="boost-img-notebooks"
            alt="notebooks"
          />
        </div>

        <PageFooterButton link="/boostchain-bot" />
      </footer>
      <SecondPartOfFooter />
    </>
  );
};
